import React from 'react'

function Footer() {
    return (
        <>
            <div className='relative border-t-2 border-[#fff]'>
                <div className='flex justify-center absolute m-auto w-full -top-[30px] '>
                    <img src="assets/images/Iconfooter.svg" alt="playicon" className='footerimages outline-offset-0' />
                </div>

                <div className='grid grid-cols-12 px-[15px] md:px-[60px] lg:px-[120px] gap-[30px] mt-[70px]'>
                    <div className="col-span-12 md:col-span-6 lg:col-span-4">
                        <div className='footersection mt-[15px]'>

                            <img src="assets/images/snipkitchenfooterlogo.svg" alt="playicon" className='footerlogo' />
                            <div className='mt-[20px] text-[14px] text-[#808080] '>
                                Donec vel ligula ornare, finibus ex at, viverra risus.
                                Aenean velit ex, finibus elementum mauris eu,
                                dignissim varius augue.
                            </div>
                            <div className='flex gap-[5px] mb-[5px] mt-[20px]'>
                                <div>
                                    <img src="assets/images/emaiicon.svg" alt="playicon" className='emailicon' />

                                </div>
                                <div className='text-[12px] text-[#131313]'>point.of.sale@gmail.com</div>
                            </div>
                            <div className='flex gap-[5px] mb-[5px] '>
                                <div className='ml-[4px]'>
                                    <img src="assets/images/earthicon.svg" alt="playicon" className='emailicon' />

                                </div>
                                <div className='text-[12px] text-[#131313] ml-[8px]'> www.pos.com</div>
                            </div>
                            <div className='flex gap-[20px] mt-[20px] ml-[4px]'>
                                <img src="assets/images/facebookicon.svg" alt="playicon" className='footerlogo' />
                                <img src="assets/images/twittericon.svg" alt="playicon" className='footerlogo' />
                                <img src="assets/images/linkdin.svg" alt="playicon" className='footerlogo' />
                                <img src="assets/images/picon.svg" alt="playicon" className='footerlogo' />

                            </div>
                            <div>


                            </div>
                            <div>

                            </div>

                        </div>

                    </div>
                    <div className="col-span-12 md:col-span-6 lg:col-span-4 ml-[0px] lg:ml-[40px]">
                        <div className='text-[#131313] font-bold text-[18px] mb-[24px]'>POS Categories</div>
                        <div className='grid grid-cols-12  text-[#9A9A9A] text-[12px]'>
                            <div className='col-span-6'>
                                <div className='mb-[5px]'>Restaurant</div>
                                <div className='mb-[5px]'>Cafe</div>
                                <div className='mb-[5px]'>Foodtruck</div>
                                <div className='mb-[5px]'>Franchise</div>
                                <div className='mb-[5px]'>Retail</div>
                                <div className='mb-[5px]'>Barbershop</div>
                                <div className='mb-[5px]'>Bakery</div>
                                <div className='mb-[5px]'>Popup-Store</div>








                            </div>
                            <div  className='col-span-6'>
                                <div className='mb-[5px]'>Foodtruck</div>
                                <div className='mb-[5px]'>Restaurant</div>
                                <div className='mb-[5px]'>Cafe</div>

                                <div className='mb-[5px]'>Foodtruck</div>
                                <div className='mb-[5px]'>Franchise</div>
                                <div className='mb-[5px]'>Barbershop</div>
                                <div className='mb-[5px]'>Retail</div>



                            </div>

                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 lg:col-span-4">
                        <div className='grid grid-cols-12'>
                            <div className='col-span-6'>
                                <div className='text-[#737373] font-bold text-[18px] mb-[24px]'>Language</div>
                                <div className='flex gap-[50px] text-[#9A9A9A] text-[12px]'>
                                    <div>
                                        <div className='mb-[5px]'>English</div>









                                    </div>


                                </div>
                            </div>
                            <div className='col-span-6'>
                            <div>
                        <div className='text-[#737373] font-bold text-[18px] mb-[24px]'>Customers</div>
                         <div className='text-[#9A9A9A] text-[12px]'>
                         <div className='mb-[5px]'>Offline POS</div>
                        <div className='mb-[5px]'>Office Login</div>
                        <div className='mb-[5px]'>Customer Support</div>

                        <div className='mb-[5px]'>Monthly Payment</div>
                         </div>
                       




                    </div>
                            </div>
                        </div>



                    </div>
                   
                </div>

                <div className='mt-[70px]'>
                    <div className='bg-[#EE9106] text-white text-center text-[15px] py-[15px]'>
                        Copyright © All Rights Reserved Snipkitchen 2022.
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer