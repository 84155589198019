import React from "react";
import Header from "./Header";
import Banner from "./Banner";
import Footer from "./Footer";
// import { Link } from "react-router-dom";
function Homepage() {
  return (
    <>
      <div className="lg:w-full md:w-full sm:w-full mx-auto">
        <Header />
        <Banner />

        {/* service section             */}
        <div>
          <div className=" text-[32px] mt-14 md:mt-24 lg:mt-24 font-bold leading-12 text-center w-full w-700 text-[#131313] ">
            Your Restaurant runs smoother with us{" "}
            <span className="text-[#EE9106]">#Our Services</span>
          </div>
          <div className="grid grid-cols-12  items-center md:mt-10 lg:md-10">
            <div className="col-span-12 lg:col-span-4 text-right ">
              <div className="w-[350px] mx-auto">
                <div className="text-center md:text-right lg:text-right">
                  <div className=" text-[24px] flex md:justify-end lg:justify-end justify-center gap-[20px]  text-[#131313] mt-[50px]">
                    <div className=" text-[#131313] text-[20px] font-medium  font-helvetica-neue  leading-7 lg:leading-7">
                      Streamlined Ordering Process:
                    </div>
                  </div>
                  <div className="text-[14px]  leading-[24px] text-[#7A7D80] mt-[15px] ">
                    Our simplifies the ordering process for both restaurants and
                    vendors, eliminating the need for numerous phone calls or
                    emails
                  </div>
                </div>
                <div className="mt-[40px] text-center md:text-right lg:text-right">
                  <div className="text-[24px] flex md:justify-end lg:justify-end justify-center gap-[20px]  text-[#131313] mt-[50px]">
                    <div className="text-[#131313] text-[20px] font-medium  font-helvetica-neue  leading-7 lg:leading-7">
                      Enhanced Visibility and Access:
                    </div>
                  </div>
                  <div className="text-[14px] w-400 leading-[24px] text-[#7A7D80] mt-[15px]">
                    Restaurants gain access to a wider range of vendors through
                    our platform, increasing their options for sourcing
                    ingredients and supplies.
                  </div>
                </div>
                <div className="mt-[40px] text-center md:text-right lg:text-right">
                  <div className="text-[24px]   gap-[20px]  text-[#131313] mt-[50px]">
                    <div className="text-[#131313] text-[20px] font-medium  font-helvetica-neue  leading-7 lg:leading-7">
                      Flexibile Vendor Selection:
                    </div>
                  </div>
                  <div className="text-[14px] w-400 leading-[24px] text-[#7A7D80] mt-[15px]">
                    Our platform offers an extensive selection of vendors,
                    providing restaurants with diverse options for sourcing
                    ingredients and supplies.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4 text-center mt-10  hidden md:block ">
              <div className="flex justify-center">
                <img
                  src="assets/images/Home Page.svg"
                  alt="playicon"
                  className="playicon"
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4 text-left">
              <div className="w-[350px] mx-auto">
                <div className="text-center md:text-left lg:text-left">
                  <div className="text-[24px] gap-[20px]  text-[#131313] mt-[50px]">
                    <div className="text-[#131313] text-[20px] font-medium  font-helvetica-neue  leading-7 lg:leading-7">
                      Customized Solutions:
                    </div>
                  </div>
                  <div className="text-[14px] w-400 leading-[24px] text-[#7A7D80] mt-[15px] ">
                    Our platform offers customizable solutions, allowing users
                    to tailor their experience according to their specific
                    requirements
                  </div>
                </div>
                <div className="mt-[40px] text-center md:text-left lg:text-left">
                  <div className="text-[24px] gap-[20px]  text-[#131313] mt-[50px]">
                    <div className="text-[#131313] text-[20px] font-medium  font-helvetica-neue  leading-7 lg:leading-7">
                      Transparent Transaction:
                    </div>
                  </div>
                  <div className="text-[14px] w-400 leading-[24px] text-[#7A7D80] mt-[15px]">
                    Our platform provides detailed transaction records,
                    including order history, invoices, and payment status,
                    ensuring transparency and facilitating smooth financial
                    transactions.
                  </div>
                </div>
                <div className="mt-[40px] text-center md:text-left lg:text-left">
                  <div className="text-[24px] gap-[20px]  text-[#131313] mt-[50px]">
                    <div className="text-[#131313] text-[20px] font-medium  font-helvetica-neue  leading-7 lg:leading-7">
                      Performance Analytics:
                    </div>
                  </div>
                  <div className="text-[14px] w-400 leading-[24px] text-[#7A7D80] mt-[15px]">
                    Our platform offers performance analytics tools, allowing
                    restaurants and vendors to track key metrics such as order
                    volume, delivery times, and product quality.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4 text-center mt-10  block  md:hidden lg:hidden ">
              <div className="flex justify-center">
                <img
                  src="assets/images/Home Page.svg"
                  alt="playicon"
                  className="playicon"
                />
              </div>
            </div>
          </div>
        </div>

        {/* client section  */}

        <div className=" mx-auto">
          <div className="mt-[70px] w-700 text-center text-[#131313] font-bold text-[32px] leading-[48px] ">
            Trusted by restaurants
            <span className="text-[#EE9106]"> #Client</span>
          </div>

          {/* ////////////////max width prob///////Tanu//////// */}
          <div className="text-center text-[#7A7D80] text-[14px] lg:w-[1200px] mx-auto mt-[17px]font-normal">
            Dependable partner for restaurants everywhere, discover why our
            clients trust Us as their preferred choice for Restaurant Solutions.
            Our commitment to excellence and customer satisfaction has
            solidified our reputation as the go-to solution for restaurant
            professionals seeking reliability, innovation, and unparalleled
            service.
          </div>
          {/* //////////////done//////////////// */}
          <div className="grid grid-cols-7 px-[60px] lg:px-[100px] mt-[50px]">
            <div className="col-span-1 ">
              <img
                src="assets/images/client-2.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
            <div className="col-span-1 ">
              <img
                src="assets/images/client-3.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
            <div className="col-span-1 ">
              <img
                src="assets/images/client-4.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
            <div className="col-span-1 ">
              <img
                src="assets/images/client-5.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
            <div className="col-span-1 ">
              <img
                src="assets/images/client-6.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
            <div className="col-span-1 ">
              <img
                src="assets/images/client-7.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
            <div className="col-span-1 ">
              <img
                src="assets/images/client-8.svg"
                alt="playicon"
                className="clientimgaes"
              />
            </div>
          </div>
          {/* <div className="mt-[40px] mx-auto w-full max-w-[1000px]">
            <img
              src="assets/images/loptop.svg"
              alt="playicon"
              className="clientimgaes"
            />
          </div> */}
        </div>

        {/* office inventory */}
        <div className=" mx-auto ">
          <div className=" mt-[50px] text-center text-[#131313] font-bold text-[32px] leading-[48px]  ">
            Comprehensive Back{" "}
            <span className="text-[#EE9106]">#Office & Inventory</span>
          </div>
          {/* //////////////////////////max width issue////////////Tanu//////////// */}
          <div className="text-center text-[#7A7D80] text-[14px] lg:w-[1200px] mx-auto mt-[17px]">
            Gain full visibility and control over your inventory, finances, and
            administrative tasks with our user-friendly platform. From tracking
            stock levels to managing invoices and expenses, our solution
            simplifies back-office processes, saving you time and reducing
            errors.
          </div>
          {/* ///////////////////////////done///////////////////////// */}
          <div className="lg:px-[250px]">
            <div className="mt-[50px]">
              <div className="grid grid-cols-12 ">
                <div className="col-span-12 md:col-span-6 lg:col-span-3 flex flex-col items-center">
                  <img
                    src="assets/images/Icon-food.svg"
                    alt="playicon"
                    className="inventoryimgaes"
                  />
                  <div className="text-[16px] font-bold text-[#131313] mt-[20px]">
                    Recipe Management & Costing
                  </div>
                  <div className="text-center text-[#7A7D80] text-[14px] mt-[17px]">
                    From ingredient sourcing to portion control, our solution
                    empowers chefs and kitchen managers to optimise recipes for
                    taste, efficiency, and cost-effectiveness.
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3 flex flex-col items-center">
                  <img
                    src="assets/images/Icon-Money_bag.svg"
                    alt="playicon"
                    className="inventoryimgaes"
                  />
                  <div className="text-[16px] font-bold text-[#131313] mt-[20px]">
                    Purchase Management
                  </div>
                  <div className="text-center text-[#7A7D80] text-[14px] mt-[17px]">
                    With intuitive tools and customisable features, you can
                    streamline your procurement workflow, ensuring timely
                    deliveries and cost-effective purchases.
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3 flex flex-col items-center">
                  <img
                    src="assets/images/Icon-graph.svg"
                    alt="playicon"
                    className="inventoryimgaes"
                  />
                  <div className="text-[16px] font-bold text-[#131313] mt-[20px]">
                    {" "}
                    Stock Management & Inventory
                  </div>
                  <div className="text-center text-[#7A7D80] text-[14px] mt-[17px]">
                    Real-time updates and intuitive reporting features, you can
                    gain valuable insights into your inventory performance,
                    minimise wastage, and ensure timely restocking.
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3 flex flex-col items-center">
                  <img
                    src="assets/images/Icon-shield.svg"
                    alt="playicon"
                    className="inventoryimgaes"
                  />
                  <div className="text-[16px] font-bold text-[#131313] mt-[20px]">
                    Powerful Inventory Reporting
                  </div>
                  <div className="text-center text-[#7A7D80] text-[14px] mt-[17px]">
                    Our platform offers robust reporting capabilities, providing
                    detailed insights into your inventory performance, trends,
                    and patterns.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Premium section  */}

        <div className="relative flex">
          <div className="mb-[80px] z-10 relative px-[30px] md:px-[120px] lg:px-[120px]">
            <div className="mt-[70px]   text-[#131313] font-bold text-[32px]  pt-[40px] leading-[48px] lg:w-[748px] lg:text-left">
              Sell Intelligently Our Brand-new retail point of sale
              <span className="text-[#EE9106]"> #Premium </span>software
            </div>
            <div className="flex flex-col items-left justify-left  gap-[27px]">
              <div className="text-[18px] text-[#7A7D80] leading-[24px] max-w-[700px] mx-auto text-left">
                Introducing Our Brand-New Retail Point of Sale #Premium
                Software. With advanced features and intuitive design, our
                software ease out the retail experience, empowering businesses
                to streamline operations, maximise sales, and delight customers.
                Say goodbye to complexity and hello to simplicity with our
                innovative solution designed to elevate your retail business to
                new heights.
              </div>
              {/* <div className="text-[#ffffff] bg-[#EE9106] px-[10px] py-[8px] text-[14px] lg:text-[16px] rounded-[4px] inline-block hover:scale-110 transition-all font-bold cursor-pointer">
                Get Demo
              </div> */}
            </div>
            <div className="text-[#ffffff] text-center w-[112px] bg-[#EE9106] px-[10px] py-[8px] text-[14px] lg:text-[16px] rounded-[4px] inline-block hover:scale-110 transition-all font-bold cursor-pointer mt-6">
              Get Demo
            </div>
          </div>
          <img
            src="assets/images/premiumimg.svg"
            alt="playicon"
            className="permiumimgaes absolute right-0 top-0 hidden lg:block"
          />
        </div>
        {/* testimonial  */}

        <div>
          <div>
            <div className="grid grid-cols-12  items-center px-[60px] lg:px-[120px]">
              <div className="col-span-12 md:col-span-6 flex-col block md:hidden lg:hidden lg:flex-row lg:space-x-5 lg:mt-0">
                <div className="text-center lg:text-end mt-[30px] lg:mt-[0px]">
                  <div className="text-[#EE9106] font-bold text-[18px]">
                    Testimonial
                  </div>
                  <div className="mt-[15px] text-[24px] font-bold text-[#131313] ">
                    What Client Say About Our Company ?
                  </div>
                  <div className="text-[#7A7D80] text-[14px] mt-[25px] leading-[24px] ">
                    Phasellus commodo id lectus blandit consectetur. Class
                    aptent taciti sociosqu ad litora torquent per conubia
                    nostra, per inceptos himenaeos. Duis conse lectus blandit
                    consectetur quat ligula quis.
                  </div>
                </div>
                <div className="flex justify-center lg:justify-end">
                  <button className=" bg-[#EE9106] text-white flex gap-[10px] rounded-[5px] font-bold items-center mt-[30px] px-[20px] py-[10px] hover:scale-110 transition-all cursor-pointer">
                    View All
                  </button>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 ">
                <div className="flex flex-col lg:flex-row lg:space-x-5 items-center  mt-[70px] lg:mt-[190px]">
                  {/* <div className="grid grid-cols-12 items-center  mt-[70px] lg:mt-[190px]"> */}
                  <div className="">
                    <div className="testimonialsboxShadow1 pt-[30px] flex flex-col items-center justify-center max-w-[280.25px]">
                      <img
                        src="assets/images/client-say-1.svg"
                        alt="playicon"
                        className=""
                      />
                      <div className="text-[18px] font-bold text-[#131313] mt-[25px] mb-[13px]">
                        Nicodemus Zorah
                      </div>
                      <div className="text-[#7A7D80] text-[14px] px-[15px] text-center pb-[20px]">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting indussimply dummy.
                      </div>
                      <div className="border-t border-[#E8E8E8] w-full ">
                        <div className="flex justify-between items-center  ">
                          <div className="text-center text-[#7A7D80] text-[14px] px-[20px] py-[8px]">
                            UX/UI Designer
                          </div>
                          <div className="flex px-[20px] py-[8px] border-l border-[#ccc]">
                            <img
                              src="assets/images/shareicon.svg"
                              alt="playicon"
                              className="mr-[5px]"
                            />
                            <div className="text-[#EE9106] text-[14px] ">
                              {" "}
                              Share
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonialsboxShadow1 pt-[30px] flex flex-col items-center justify-center mt-[50px] max-w-[280.25px]">
                      <img
                        src="assets/images/client-say-1.svg"
                        alt="playicon"
                        className=""
                      />
                      <div className="text-[18px] font-bold text-[#131313] mt-[25px] mb-[13px]">
                        Nicodemus Zorah
                      </div>
                      <div className="text-[#7A7D80] text-[14px] px-[15px] text-center pb-[20px]">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting indussimply dummy.
                      </div>
                      <div className="border-t border-[#E8E8E8] w-full ">
                        <div className="flex justify-between items-center  ">
                          <div className="text-center text-[#7A7D80] text-[14px] px-[20px] py-[8px]">
                            UX/UI Designer
                          </div>
                          <div className="flex px-[20px] py-[8px] border-l border-[#ccc]">
                            <img
                              src="assets/images/shareicon.svg"
                              alt="playicon"
                              className="mr-[5px]"
                            />
                            <div className="text-[#EE9106] text-[14px] ">
                              {" "}
                              Share
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 max-w-[280.25px] md:ml-5 lg:ml-5 2xl:ml-5 mt-12">
                    <div className="testimonialsboxShadow1 pt-[30px] flex flex-col items-center justify-center ">
                      <img
                        src="assets/images/client-say-1.svg"
                        alt="playicon"
                        className=""
                      />
                      <div className="text-[18px] font-bold text-[#131313] mt-[25px] mb-[13px]">
                        Nicodemus Zorah
                      </div>
                      <div className="text-[#7A7D80] text-[14px] px-[15px] text-center pb-[20px]">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting indussimply dummy.
                      </div>
                      <div className="border-t border-[#E8E8E8] w-full ">
                        <div className="flex justify-between items-center  ">
                          <div className="text-center text-[#7A7D80] text-[14px] px-[20px] py-[8px]">
                            UX/UI Designer
                          </div>
                          <div className="flex px-[20px] py-[8px] border-l border-[#ccc]">
                            <img
                              src="assets/images/shareicon.svg"
                              alt="playicon"
                              className="mr-[5px]"
                            />
                            <div className="text-[#EE9106] text-[14px] ">
                              {" "}
                              Share
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 flex-col hidden md:block lg:block lg:flex-row lg:space-x-5 mt-8 lg:mt-0">
                <div className="text-center lg:text-end mt-[30px] lg:mt-[0px]">
                  <div className="text-[#EE9106] font-bold text-[18px]">
                    Testimonial
                  </div>
                  <div className="mt-[15px] text-[24px] font-bold text-[#131313] ">
                    What Client Say About Our Company ?
                  </div>
                  <div className="text-[#7A7D80] text-[14px] mt-[25px] leading-[24px] ">
                    Phasellus commodo id lectus blandit consectetur. Class
                    aptent taciti sociosqu ad litora torquent per conubia
                    nostra, per inceptos himenaeos. Duis conse lectus blandit
                    consectetur quat ligula quis.
                  </div>
                </div>
                <div className="flex justify-center lg:justify-end">
                  <button className=" bg-[#EE9106] text-white flex gap-[10px] rounded-[5px] font-bold items-center mt-[30px] px-[20px] py-[10px] hover:scale-110 transition-all cursor-pointer">
                    View All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* pricing table  */}

        <div className="px-[20px] md:px-[0px] lg:px-[0px]">
          {/* <div className="max-w-[800px] w-full mx-auto mt-[70px] text-center text-[#131313] font-sans font-bold text-[28px] lg:text-[40px] px-[30px] lg:px-[0px] py-[0px] leading-[30px] lg:leading-[40px]"> */}
          <div className="font-sans text-center text-[32px]  font-bold text-xl md:text-2xl lg:text-3xl w-full mt-24 leading-[38.4px] ">
            Explore Our Subscription Model for Exclusive Benefits and Special
            Offers
            <br />
            <span className="text-[#EE9106]"> #Pricing Table </span>
          </div>

          <div className="max-w-[1000px] w-full mx-auto">
            <div className="grid grid-cols-12 justify-center items-center gap-[20px] md:gap-[40px] lg:gap-[40px] mt-[30px]">
              <div className="col-span-12 md:col-span-6 lg:col-span-4 px-[30px] md:px-[60px] lg:px-[0px]">
                <div className="boxShadow1 text-center py-[50px] ">
                  <div className="flex justify-center">
                    <img
                      src="assets/images/monyimage.svg"
                      alt="playicon"
                      className="bg-[#EE9106] p-[13px] rounded-[6px] boxShadow1"
                    />
                  </div>
                  <div className="text-[#131313] font-bold text-[40px] pt-[25px] leading-[32px]">
                    Small
                  </div>
                  <div className="flex flex-col items-center justify-center ">
                    <div className="ml-[25px] mt-[15px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div className="text-[16px] text-[#808080]">
                          {" "}
                          Up to 1 Outlet
                        </div>
                      </div>
                    </div>
                    <div className="mt-[15px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div className="text-[16px] text-[#808080]">
                          {" "}
                          Unlimited Devices
                        </div>
                      </div>
                    </div>
                    <div className="mt-[15px] ml-[40px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div className="text-[16px] text-[#808080]">
                          {" "}
                          10 Users
                        </div>
                      </div>
                    </div>
                    <div className="mt-[15px] ml-[25px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div className="text-[16px] text-[#808080]">
                          {" "}
                          Free Support
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center relative">
                    <div className="flex  mt-[20px]">
                      <img
                        src="assets/images/dollerimage.svg"
                        alt="playicon"
                        className=""
                      />
                      <div className=" text-[32px] font-italic font-bold">
                        50
                      </div>
                      <div className="font-bold text-[14px] mt-[20px]">
                        <sub>/Month</sub>
                      </div>
                    </div>
                    <div className="-rotate-90 absolute right-[-28px] bottom-[0px]">
                      <button className="boxShadow2 bg-[#EE9106] text-white flex gap-[10px] rounded-[6px] items-center  px-[20px] py-[10px] text-[14px] font-bold hover:scale-110 transition-all">
                        {" "}
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 px-[30px] md:px-[60px] lg:px-[0px]">
                <div className="boxShadow1 text-center py-[50px] ">
                  <div className="flex justify-center">
                    <img
                      src="assets/images/monyimage.svg"
                      alt="playicon"
                      className="bg-[#EE9106] p-[13px] rounded-[6px] boxShadow1"
                    />
                  </div>
                  <div className="text-[#131313] font-bold text-[40px] pt-[25px] leading-[32px] ">
                    Small
                  </div>
                  <div className="flex flex-col items-center justify-center text-[16px] text-[#808080]">
                    <div className="ml-[25px] mt-[15px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> Up to 1 Outlet</div>
                      </div>
                    </div>
                    <div className="mt-[15px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> Unlimited Devices</div>
                      </div>
                    </div>
                    <div className="mt-[15px] ml-[40px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> 10 Users</div>
                      </div>
                    </div>
                    <div className="mt-[15px] ml-[25px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> Free Support</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center relative ">
                    <div className="flex  mt-[20px]">
                      <img
                        src="assets/images/dollerimage.svg"
                        alt="playicon"
                        className=""
                      />
                      <div className=" text-[32px] font-italic font-bold">
                        50
                      </div>
                      <div className="font-bold text-[14px] mt-[20px]">
                        <sub>/Month</sub>
                      </div>
                    </div>
                    <div className="-rotate-90 absolute right-[-28px] bottom-[0px]">
                      <button className="boxShadow2 bg-[#EE9106] text-white flex gap-[10px] rounded-[6px] items-center  px-[20px] py-[10px] text-[14px] font-bold hover:scale-110 transition-all">
                        {" "}
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4  px-[30px] md:px-[60px] lg:px-[0px]">
                <div className="boxShadow1 text-center py-[50px] ">
                  <div className="flex justify-center">
                    <img
                      src="assets/images/monyimage.svg"
                      alt="playicon"
                      className="bg-[#EE9106] p-[13px] rounded-[6px] boxShadow1"
                    />
                  </div>
                  <div className="text-[#131313] font-bold text-[40px] pt-[25px] leading-[32px]">
                    Small
                  </div>
                  <div className="flex flex-col items-center justify-center text-[16px] text-[#808080] ">
                    <div className="ml-[25px] mt-[15px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> Up to 1 Outlet</div>
                      </div>
                    </div>
                    <div className="mt-[15px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> Unlimited Devices</div>
                      </div>
                    </div>
                    <div className="mt-[15px] ml-[40px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> 10 Users</div>
                      </div>
                    </div>
                    <div className="mt-[15px] ml-[25px]">
                      <div className="flex gap-[10px]">
                        <img
                          src="assets/images/checkedimage.svg"
                          alt="playicon"
                          className=""
                        />

                        <div> Free Support</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center relative">
                    <div className="flex  mt-[20px]">
                      <img
                        src="assets/images/dollerimage.svg"
                        alt="playicon"
                        className=""
                      />
                      <div className=" text-[32px] font-italic font-bold">
                        50
                      </div>
                      <div className="font-bold text-[14px] mt-[20px]">
                        <sub>/Month</sub>
                      </div>
                    </div>
                    <div className="-rotate-90 absolute right-[-28px] bottom-[0px]">
                      <button className="boxShadow2 bg-[#EE9106] text-white flex gap-[10px] rounded-[6px] items-center  px-[20px] py-[10px] text-[14px] font-bold hover:scale-110 transition-all">
                        {" "}
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Latest News  */}
        <div className="px-[20px] md:px-[0px] lg:px-[0px]">
          <div className="mt-[70px] text-center text-[#131313] font-bold text-[32px] py-[0px]">
            Point Of Sale<span className="text-[#EE9106]"> #Latest News </span>
          </div>
          <div className="text-center text-[#7A7D80] text-[14px] leading-[24px]  mx-auto mt-[17px]">
            Nam at dignissim tortor. Sed efficitur leo in nibh euismod
            imperdiet. Aliquam ut sodales odio. Vestibulum posuere felis at
            fermentum pharetra. Mauris ac tellus magna.
          </div>
          <div className="grid grid-cols-12 gap-[30px] mt-[30px] md:px-[60px]  lg:px-[120px]">
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="flex gap-[40px] ">
                <div className="relative">
                  <div className="bg-[#EFF0F1] py-[40px] px-[30px]"></div>
                  <div className="-rotate-90 text-[#808080] absolute left-[-90px] top-0 w-[200px]">
                    12 May 2018
                  </div>
                </div>
                <div>
                  <button className="hover:scale-110 transition-all boxShadow1 bg-[#EE9106] text-white flex gap-[10px] rounded-[5px] items-center  px-[20px] py-[10px] text-[14px] font-bold">
                    Restaurant
                  </button>
                  <div className="text-[#131313] text-[18px] font-bold mt-[27px]">
                    Donec vel ligula ornare, fin ibus ex at, viverra.
                  </div>
                  <div className="text-[14px] leading-[24px] text-[#808080] mt-[15px]">
                    Donec vel ligula ornare, finibus ex at, viverra risus.
                    Aenean velit ex.
                  </div>
                  <div className="mt-5 flex gap-6" >
                    <div className="flex gap-2" >
                      <img src="assets/images/Icon.svg" /> <span className="text-[#808080] text-[15px] font-[400] " >500</span>
                    </div>

                    <div className="flex gap-2" >
                      <img src="assets/images/maill.svg" /> <span className="text-[#808080] text-[15px] font-[400] " >50</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="flex gap-[40px] ">
                <div className="relative">
                  <div className="bg-[#EFF0F1] py-[40px] px-[30px]"></div>
                  <div className="-rotate-90 text-[#808080] absolute left-[-90px] top-0 w-[200px]">
                    12 May 2018
                  </div>
                </div>
                <div>
                  <button className="hover:scale-110 transition-all boxShadow1 bg-[#EE9106] text-white flex gap-[10px] rounded-[5px] items-center  px-[20px] py-[10px] text-[14px] font-bold">
                    Restaurant
                  </button>
                  <div className="text-[#131313] text-[18px] font-bold mt-[27px]">
                    Donec vel ligula ornare, fin ibus ex at, viverra.
                  </div>
                  <div className="text-[14px] leading-[24px] text-[#808080] mt-[15px]">
                    Donec vel ligula ornare, finibus ex at, viverra risus.
                    Aenean velit ex.
                  </div>
                  <div className="mt-5 flex gap-6" >
                    <div className="flex gap-2" >
                      <img src="assets/images/Icon.svg" /> <span className="text-[#808080] text-[15px] font-[400] " >500</span>
                    </div>

                    <div className="flex gap-2" >
                      <img src="assets/images/maill.svg" /> <span className="text-[#808080] text-[15px] font-[400] " >50</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <div className="flex gap-[40px] ">
                <div className="relative">
                  <div className="bg-[#EFF0F1] py-[40px] px-[30px]"></div>
                  <div className="-rotate-90 text-[#808080] absolute left-[-90px] top-0 w-[200px]">
                    12 May 2018
                  </div>
                </div>
                <div>
                  <button className="hover:scale-110 transition-all boxShadow1 bg-[#EE9106] text-white flex gap-[10px] rounded-[5px] items-center  px-[20px] py-[10px] text-[14px] font-bold">
                    Restaurant
                  </button>
                  <div className="text-[#131313] text-[18px] font-bold mt-[27px]">
                    Donec vel ligula ornare, fin ibus ex at, viverra.
                  </div>
                  <div className="text-[14px] leading-[24px] text-[#808080] mt-[15px]">
                    Donec vel ligula ornare, finibus ex at, viverra risus.
                    Aenean velit ex.
                  </div>
                  <div className="mt-5 flex gap-6" >
                    <div className="flex gap-2" >
                      <img src="assets/images/Icon.svg" /> <span className="text-[#808080] text-[15px] font-[400] " >500</span>
                    </div>

                    <div className="flex gap-2" >
                      <img src="assets/images/maill.svg" /> <span className="text-[#808080] text-[15px] font-[400] " >50</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* demo-section */}
        <div>
          <div className="grid grid-cols-12 gap-[0px] lg:gap-[80px] rounded-lg px-[20px] md:px-[60px] lg:px-[170px]  my-[80px]">
            <div className="col-span-12 lg:col-span-6">
              <div className="boxShadow relative">
                <div className="pb-[70px] pt-[50px] border-b border-[#F3F3F3] text-center text-[22px] font-bold">
                  Yes, I want to get a demo
                </div>
                <div className="grid grid-cols-12 gap-[8px] px-[24px] mt-[20px]">
                  <div className="col-span-6">
                    <div className="text-[#131313] font-bold text-[14px]">
                      First name *
                    </div>
                    <div className="mt-[8px]">
                      <input
                        type="text"
                        className="p-[8px] border-[1px] border-[#F3F3F3] w-full rounded-[10px] placeholder:text-[14px] 
                     "
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <div className="text-[#131313] font-bold text-[14px]">
                      Last name *
                    </div>
                    <div className="mt-[8px]">
                      <input
                        type="text"
                        className="p-[8px] border-[1px] border-[#F3F3F3] w-full rounded-[10px] placeholder:text-[14px] 
                     "
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="assets/images/calendly.svg"
                    alt="playicon"
                    className=" absolute right-[0px] top-[0px]"
                  />
                </div>
                <div className="mt-[24px] px-[24px]">
                  <div className="text-[#131313] font-bold text-[14px]">
                    Email *
                  </div>
                  <div className="mt-[8px]">
                    <input
                      type="text"
                      className="p-[8px] border-[1px] border-[#F3F3F3] w-full rounded-[10px] placeholder:text-[14px] 
                     "
                    />
                  </div>
                </div>
                <div className="mt-[24px] px-[24px]">
                  <div className="text-[#131313] font-bold text-[14px]">
                    Phone number *
                  </div>
                  <div className="mt-[8px]">
                    <div className="relative">
                      <input
                        type="text"
                        className=" p-[8px] border-[1px] border-[#F3F3F3] w-full rounded-[10px] placeholder:text-[14px] 
                     "
                      />
                      <img
                        src="assets/images/flag.svg"
                        alt="playicon"
                        className="flagimgaes absolute left-[15px] top-[15px]"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-[24px] px-[24px]">
                  <div className="text-[#131313] font-bold text-[14px]">
                    Company Name *
                  </div>
                  <div className="mt-[8px]">
                    <input
                      type="text"
                      className="p-[8px] border-[1px] border-[#F3F3F3] w-full rounded-[10px] placeholder:text-[14px] 
                     "
                    />
                  </div>
                </div>
                <div className="mt-[24px] px-[24px]">
                  <div className="text-[#131313] font-bold text-[14px]">
                    Business Type *
                  </div>
                  <div className="mt-[8px]">
                    <input
                      type="text"
                      className="p-[8px] border-[1px] border-[#F3F3F3] w-full rounded-[10px] placeholder:text-[14px] 
                     "
                    />
                  </div>
                </div>
                <div className="pb-[30px]">
                  <div className="hover:scale-110 transition-all mt-[30px]  mx-[24px] bg-[#0069FF] p-[10px] flex justify-center text-white text-[14px] rounded-[100px] cursor-pointer">
                    Submit
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 mt-[30px] lg:mt-[0px]">
              <img
                src="assets/images/demoimages.svg"
                alt="playicon"
                className="flagimgaes hidden lg:block"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
