// import { Link, useLocation } from 'react-router-dom';
import React, { useState } from "react";
function Header() {
  // const location = useLocation();

  // const isActive = (path) => {
  //   return location.pathname === path ? 'active' : '';
  // };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    // <div className='flex justify-between items-center'>
    //   <div>
    //     <img src="assets/images/snip kitchen logo.svg" alt="Logo" />
    //   </div>
    //   <div className='flex items-center'>
    //     <div className={isActive('/home')}> {/* Check if the current path is '/home' */}
    //       <Link to={"/home"}>Home</Link>
    //     </div>
    //     <div className={isActive('/pricing')}>
    //       <Link to={"/pricing"}>Pricing</Link>
    //     </div>
    //     <div className={isActive('/page-features')}>
    //       <Link to={"/page-features"}>Page Features</Link>
    //     </div>
    //     <div className={isActive('/blog')}>
    //       <Link to={"/blog"}>Blog</Link>
    //     </div>
    //     <div className={isActive('/contact-us')}>
    //       <Link to={"/contact-us"}>Contact Us</Link>
    //     </div>
    //   </div>
    //   <div className='flex items-center'>
    //     <div>Sign Up</div>
    //     <div>Login</div>
    //     <div>Get Demo</div>
    //   </div>
    // </div>
    <>
      <div className="flex justify-around items-center my-[18px] desktopmenu">
        <div>
          <img src="assets/images/snip kitchen logo.svg" />
        </div>
        <div className="flex items-center gap-[40px]">
          <div className="text-[#EE9106] font-medium text-[16px] cursor-pointer">
            Home
          </div>
          <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
            Pricing
          </div>

          <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
            Page Features
          </div>

          <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
            Blog
          </div>

          <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
            Contact Us
          </div>
        </div>
        <div className="flex items-center gap-[40px] ">
          <div className="text-[#EE9106] text-[16px] border border-solid border-orange-400 px-3 rounded-sm cursor-pointer">
            Login
          </div>
          <div className="mr-[-15px]">
            <div className="text-[#646772] text-[16px] divide-x divide-[#C3C4C7] cursor-pointer">
              Sign Up{" "}
            </div>
          </div>
          <div className="w-[1px] h-[30px] bg-[#C3C4C7]"></div>
          <div className="text-[#ffffff] bg-[#EE9106] px-[10px] py-[5px] text-[16px] rounded-[4px]  ml-[-20px] cursor-pointer">
            Get Demo
          </div>
          <div className=""></div>
        </div>
      </div>
      <div className="hamburger-menu bg-[#f3f9fe]">
        <div className="ml-[20px] pt-[10px]">
          <img src="assets/images/snip kitchen logo.svg" />
        </div>
        <div
          className={isOpen ? "menu-icon open" : "menu-icon"}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        {isOpen && (
          <div className="menu">
            <ul>
              <li>
                <div className="text-[#EE9106] font-medium text-[16px] cursor-pointer">
                  Home
                </div>
              </li>
              <li>
                <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
                  Pricing
                </div>
              </li>

              <li>
                <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
                  Page Features
                </div>
              </li>

              <li>
                <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
                  Blog
                </div>
              </li>

              <li>
                <div className="text-[#131313] hover:text-[#EE9106] font-medium text-[16px] cursor-pointer">
                  Contact Us
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
