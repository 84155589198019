import React from 'react'
import Homepage from './components/Homepage'
// import Header from './components/Header'
// import Banner from './components/Banner'

function App() {
  return (
   <>
   <Homepage/>
   {/* <Header/>
   <Banner/> */}
   </>
  )
}

export default App