import React from "react";

function Banner() {
  return (
    <>
      <section className="bg-[#F3F9FE] pt-16 md:py-32 lg:py-32">
        <div className="grid grid-cols-12 items-center px-[25px] ">
          <div className="col-span-12 lg:col-span-6  ">
            <div className="mx-auto w-full md:w-[608px]  gap-[16px]">
              <div className="font-bold text-xl md:text-2xl lg:text-3xl leading-14 md:leading-16 lg:leading-20 w-full md:w-608">
                Meet <span className="text-[#EE9106]">Snipkitchen</span>,
                Effortless Ordering Solutions for Restaurant Kitchens.{" "}
              </div>
              <div className="font-normal text-sm md:text-base   lg:text-lg leading-6 md:leading-7 lg:leading-6 text-left text-[rgb(122,125,128)] md:mt-4">
                Our platform streamlines the ordering process, providing a
                centralised hub for restaurants to efficiently connect with
                vendors. With Snipkitchen, restaurants can easily place orders
                and access a wide range of products tailored to their needs. Say
                goodbye to the hassle of multiple phone calls and emails –
                Snipkitchen is here to revolutionize restaurant supply
                management.
              </div>
              <button className="hover:scale-110 transition-all bg-[#EE9106] font-bold text-white text-[14px] flex gap-[10px] rounded-[5px] items-center md:mt-[80px] xl:mt-[80px] 2xl:mt-[80px] mt-[30px] px-[15px] py-[5px] md:px-[20px] md:py-[10px]">
                {/* <img
                  src="assets/images/__before.svg"
                  alt="playicon"
                  className="playicon"
                /> */}
                Get Demo
              </button>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 ">
            <div className="flex justify-center pt-8">
              <img
                src="assets/images/HeaderImg!.svg"
                alt="bannerImg"
                className="mx-3.5mx"
              />
            </div>
          </div>
        </div>
        {/* <img
          src="assets/images/banner-shape.svg"
          alt="bannerImg"
          className="bannershapeimage"
        /> */}
      </section>
    </>
  );
}

export default Banner;
